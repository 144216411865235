const getCookie = (key) => {
  const [uniteToken] = document.cookie
    .split(";")
    .map((cookie) => cookie.trim().split("="))
    .filter(([k]) => k === key);

  if (uniteToken !== undefined) return uniteToken[1];

  return undefined;
};

const setCookie = (key, value) => {
  const newCookie = [`${key}=${value}`, ...document.cookie.split(";")].join(
    ";"
  );

  document.cookie = newCookie;
};

export { getCookie, setCookie };
