import { useEffect } from "react";
import { getCookie } from "./util/cookie";
import "./style/skeleton.css";
import axios from "axios";

function App() {
  const cookieToken = getCookie("unite_token");
  const path = window.location.pathname; // 현재 path
  const query = window.location.search;

  useEffect(() => {
    const searchParam = new URLSearchParams(query);
    const token = cookieToken || searchParam.get("token");

    axios
      .get("https://master.unite.smartdoctorapi.cc/version-check", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r) => {
        const environment =
          r.data.version === "old" ? "sub-master" : "sub-main";

        searchParam.set("token", token);

        const url = new URL(
          path,
          `https://${environment}.msg.smartdoctorapi.cc`
        );

        url.search = searchParam.toString();
        url.protocol = "https";

        // alert(url.toString());
        window.location.href = url.toString();
      })
      .catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="skeleton-list-item"
      style={{
        margin: "10px",
        height: "calc(100vh - 40px)",
        width: "calc(100vw - 40px)",
      }}
    ></div>
  );
}

export default App;
